import PopUpRenterAppDownload from 'components/PopUpRenterAppDownload';
import { BrandReview } from 'components/homepage/brandReview';
import Experiences from 'components/homepage/experiences';
import { ExperiencesSummerCollection } from 'components/homepage/experiencesSummerCollection';
import FadeInSection from 'components/homepage/fadeInSection';
import Hero from 'components/homepage/hero';
import { HowWorks } from 'components/homepage/howWorks';
import { Owner } from 'components/homepage/owner';
import { Review } from 'components/homepage/review';
import SearchBar from 'components/homepage/searchBar';
import SEOLinks from 'components/homepage/seoLinks';
import { useGetFeatureFlagByName } from 'components/layouts/hooks';
import { mediaLgMax, mediaSmMax } from 'helpers/breakpoints';
import useMobilePopup from 'hooks/useMobilePopup';
import getConfig from 'next/config';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import styled from 'styled-components';
import boatsetterPaths from 'utils/boatsetterPaths';
import jsonReviewsData from '../public/images/json/reviews.json';
import { SpecialOffer } from '../src/app/components/homepage/specialOffer';
import { howWorksData } from '../src/app/components/homepage/utils';
import DefaultLayout from '../src/app/components/layouts/DefaultLayout';
import { RENTER_APP_ID, getItunesAppUniversalLinkMetaTag } from '../src/app/helpers/templateHelpers';

const { RAILS_URL, BS_ENV } = getConfig().publicRuntimeConfig;

const HomePage = () => {
  const experiencesSummerCollectionEnabled = useGetFeatureFlagByName('experiences_summer_collection');

  const downloadRenterAppPopUp = useMobilePopup({
    popUpName: 'downloadRenterAppPopUp',
    showDelay: 0,
    PopUpComponent: PopUpRenterAppDownload,
    lazyLoad: true,
    popUpComponentProps: {
      iOSLink: 'https://apps.apple.com/app/apple-store/id1449873080?pt=43037800&ct=Renterapp_popup_v2&mt=8',
      androidLink:
        'https://play.google.com/store/apps/details?id=com.boatsetterrenters&referrer=utm_source%3Dboatsetter.com%26utm_campaign%3DRenterapp_popup_v1',
    },
  });
  const [pageSections] = useState([
    {
      component: (
        <Owner
          orientation="left"
          images={{
            mobileJPG: '/images/homepage/owner_mobile.jpg',
            mobileWEB: '/images/homepage/owner_mobile.webp',
            desktopJPG: '/images/homepage/owner.jpg',
            desktopWEB: '/images/homepage/owner.webp',
          }}
        >
          <h2>Put your boat to work</h2>
          <p>Offset the cost of boat ownership by listing it on Boatsetter.</p>
          <Link href={boatsetterPaths.whyList}>Learn more</Link>
        </Owner>
      ),
      hasAnimation: false,
    },
    // { component: <FeaturedBoats />, hasAnimation: true },
    { component: <Review />, hasAnimation: true },
    { component: <BrandReview />, hasAnimation: true },
    {
      component: (
        <Owner
          orientation="right"
          images={{
            mobileJPG: '/images/homepage/captain_mobile.jpg',
            mobileWEB: '/images/homepage/captain_mobile.webp',
            desktopJPG: '/images/homepage/captain.jpg',
            desktopWEB: '/images/homepage/captain.webp',
          }}
        >
          <h2>Are you an experienced captain?</h2>
          <p>Join our Captain Network and make money running boats.</p>
          <a href={boatsetterPaths.becomeACaptain}>Learn more</a>
        </Owner>
      ),
      hasAnimation: true,
    },
    {
      component: <SEOLinks />,
      hasAnimation: false,
    },
  ]);

  const router = useRouter();

  const reviews = `
            {
                "@context": "http://schema.org",
                "@type": "Product",
                "name": "Boat Rentals",
                "image": "${RAILS_URL}/images/homepage/hero.jpg",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "${jsonReviewsData.review_rating}",
                    "reviewCount": "${jsonReviewsData.review_count}",
                    "bestRating": "5",
                    "worstRating": "1"
                }
            }
            `;

  const title = 'Boat Rentals, Charter Boats Near Me, Yacht Rentals & More | Boatsetter';
  const description =
    'Rent a boat today, with or without a boat captain, and get out on the water! Browse our huge selection of boat rentals near you including yachts, pontoons, sailboats, fishing boats, wakeboarding boats, Catamarans & more!';

  const brand = `
            {
                "@context": "http://schema.org",
                "@type": "Brand",
                "name": "Boatsetter",
                "description": "${description}"
            }
            `;

  return (
    <DefaultLayout isHome isHomePage noFollow={false} white ownerNav={false}>
      <HomepageWrapper id="homePage">
        <Head>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:url" content="https://boatsetter.com" />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="/images/homepage/hero.jpg" />
          <meta property="og:description" content={description} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@Boatsetter" />
          <meta name="twitter:creator" content="@Boatsetter" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content="/images/homepage/hero.jpg" />

          <meta name="facebook-domain-verification" content="upbap294hagzwgwo7u18t9xz0pqlkb" />
          {getItunesAppUniversalLinkMetaTag(router.asPath, RENTER_APP_ID)}

          {BS_ENV !== 'development' && <link rel="canonical" href={RAILS_URL} />}
        </Head>

        {downloadRenterAppPopUp}

        <SpecialOffer isVisible={false} />
        <Hero />
        <SearchBar />
        <HowWorks
          reducedTest
          heading={
            <>
              How it works
              <br />I am a...
            </>
          }
          data={howWorksData}
          sections={['Renter', 'Owner', 'Captain']}
        />
        {experiencesSummerCollectionEnabled && (
          <ExperiencesSummerCollection
            orientation="right"
            images={{
              mobileJPG: '/images/homepage/Collection002_mobile.png',
              desktopJPG: '/images/homepage/Collection002_desktop.png',
            }}
          >
            <h2>Climb aboard</h2>
            <p>
              Introducing a curated collection of unique things to do out on the water. From sailing with a local
              expert, to exploring remote islands, or discovering a secluded cove at home.
            </p>
            <Link href={boatsetterPaths.newCollection}>Explore now</Link>{' '}
          </ExperiencesSummerCollection>
        )}
        <Experiences reducedTest={!experiencesSummerCollectionEnabled} />
        {pageSections.map((section, id) => (
          <Fragment key={id}>
            {section.hasAnimation ? (
              <FadeInSection key={id}>{section.component}</FadeInSection>
            ) : (
              <Fragment key={id}>{section.component}</Fragment>
            )}
          </Fragment>
        ))}

        <script dangerouslySetInnerHTML={{ __html: reviews }} type="application/ld+json" />
        <script dangerouslySetInnerHTML={{ __html: brand }} type="application/ld+json" />
      </HomepageWrapper>
    </DefaultLayout>
  );
};

export default HomePage;

const HomepageWrapper = styled.div`
  * {
    font-family: 'AvenirNext', 'Avenir', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .info > div {
    @media ${mediaSmMax} {
      height: 160px;
    }
  }

  .cap {
    max-width: 1076px;
    width: 100%;
    margin: auto;
    position: relative;
    //overflow-y: hidden;

    @media ${mediaLgMax} {
      margin: 0 40px;
      width: unset;
    }

    @media ${mediaSmMax} {
      margin: 0 20px;
      width: unset;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 28px;

    @media ${mediaSmMax} {
      font-size: 20px;
      line-height: 24px;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: bold;

    @media ${mediaSmMax} {
      font-size: 16px;
      line-height: 28px;
    }
  }
`;
