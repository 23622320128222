import { useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import styled from 'styled-components';
import { mediaMdMax, mediaSmMax } from 'helpers/breakpoints';
import cc from 'classcat';
import { useAnimationHook } from './animationUtils';
import FadeInSection from './fadeInSection';

export const HowWorks = ({ data, heading, sections, reducedTest }) => {
  const [section, setSection] = useState(sections[0]);

  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useAnimationHook(domRef, setVisible);

  return (
    <HowWorksWrapper className={cc(['howWorks', 'cap', { reducedContentPadding: reducedTest }])} ref={domRef}>
      <FadeInSection isSlow isVisibleProp={isVisible} isSmallerMargin>
        <div className="menu">
          <h2>{heading}</h2>
          <div className="links">
            {sections.map((cSection) => (
              <div
                data-testid="homepage-howWorks-user-type-link"
                key={cSection}
                onClick={() => setSection(cSection)}
                className={cSection === section ? 'active' : ''}
              >
                {cSection}
              </div>
            ))}
          </div>
        </div>
      </FadeInSection>
      <FadeInSection isSlow isRight isVisibleProp={isVisible} isSmallerMargin>
        <div className="info">
          {data[section].map((item, i) => (
            <div key={item.text + item.title}>
              <FadeIn transitionDuration={400} delay={0}>
                <img
                  loading="lazy"
                  height={i !== 0 ? 25 : 27}
                  width={i !== 0 ? 25 : 40}
                  src={item.icon}
                  className={i === 0 ? 'searchIcon' : ''}
                  alt="Search"
                  data-testid="homepage-howWorks-user-type-link-icon"
                />
              </FadeIn>

              <FadeIn transitionDuration={500} delay={0}>
                <h4 data-testid="homepage-howWorks-user-type-link-title">{item.title}</h4>
              </FadeIn>
              <FadeIn transitionDuration={800} delay={0}>
                <p data-testid="homepage-howWorks-user-type-link-text">{item.text}</p>
              </FadeIn>
            </div>
          ))}
        </div>
      </FadeInSection>
    </HowWorksWrapper>
  );
};

const HowWorksWrapper = styled.div`
  padding-top: 100px;
  height: calc(250px + 150px);
  display: flex;

  @media ${mediaSmMax} {
    margin-left: 0 !important;
    height: calc(250px + 50px);
  }

  @media ${mediaMdMax} {
    padding-top: 0px;
    margin-bottom: 335px !important;
    height: calc(250px + 50px);
  }

  &.reducedContentPadding {
    padding-top: 230px;

    @media ${mediaMdMax} {
      margin-top: 250px !important;
      margin-bottom: 355px !important;
      padding-top: 0;
    }

    @media ${mediaSmMax} {
      padding-top: 0;
      margin-top: 105px !important;
    }
  }

  .info {
    background: #ffffff;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    position: relative;
    display: flex;
    top: 50px;
    gap: 7px;
    padding: 38px;
    left: -50px;
    @media ${mediaSmMax} {
      position: absolute;
      padding: 30px;
      gap: unset !important;
      left: 20px !important;
      width: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @media ${mediaMdMax} {
      position: absolute;
      left: 40px;
      gap: 20px;
      top: calc(100% - 100px);
    }

    & > div {
      display: block;
      width: 165px;

      @media ${mediaSmMax} {
        height: 180px;
      }

      @media ${mediaMdMax} {
        width: 47%;
        & > div {
          max-width: 145px;
        }
      }

      &:not(:last-child) {
        padding-right: 20px;
        @media ${mediaMdMax} {
          padding-right: unset;
        }
      }

      h4 {
        font-weight: bold;
        padding-bottom: 10px;
        @media ${mediaSmMax} {
          padding-bottom: 5px;
        }
      }

      img {
        margin-bottom: 13px;
        &.searchIcon {
          margin-bottom: 13px;
          margin-top: -3px;
          @media ${mediaSmMax} {
            margin-bottom: 6px;
          }
        }
        @media ${mediaSmMax} {
          margin-bottom: 5px;
        }
      }
    }

    @keyframes inAnimation {
      0% {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
      100% {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }

    @keyframes outAnimation {
      0% {
        opacity: 1;
        display: block;
      }
      100% {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }
  }

  .menu {
    padding: 40px 80px 40px 40px;
    max-width: 370px;
    min-height: fit-content;
    background-color: #083170;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      padding-bottom: 20px;
      color: #72d4ba;

      @media ${mediaSmMax} {
        font-size: 24px;
        line-height: 29px;
      }
    }

    @media ${mediaSmMax} {
      padding: 30px 35px 20px 20px;
    }

    @media ${mediaMdMax} {
      max-width: 600px;
    }

    .links {
      display: flex;
      div {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.49);
        font-weight: bold;
        margin-right: 30px;
        font-size: 16px;
        padding-bottom: 5px;
        transition: color 200ms ease-in-out;

        &:hover {
          color: white;
          transition: color 200ms ease-in-out;
        }
        &.active {
          color: white;
          transition: color 200ms ease-in-out;
          border-bottom: 2px solid #72d4ba;
        }
      }
    }
  }
`;
