import { BreakpointContext } from 'components/BreakpointProvider';
import { useContext } from 'react';
import { queries as defaultQueries } from '../helpers/breakpoints';

type queryKeyType = keyof typeof defaultQueries;

export default function useBreakpoints(...queries: queryKeyType[][]): boolean[] {
  const context = useContext(BreakpointContext);
  if (!context) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }

  const resolvedQueries = queries.map((breakpoints) =>
    breakpoints.map((breakpoint) => context[breakpoint]).some((val) => val === true)
  );

  return resolvedQueries;
}

export const SMALLSCREEN_QUERY: queryKeyType[] = ['xs', 'sm'];
