import { mediaMdMax, mediaMdMin, mediaSmMax } from 'helpers/breakpoints';
import Image from 'next/image';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useAnimationHook } from './animationUtils';
import FadeInSection from './fadeInSection';

export const ExperiencesSummerCollection = ({ children, orientation, images }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useAnimationHook(domRef, setVisible);

  const { desktopJPG, mobileJPG } = images;

  return (
    <ExperiencesSummerCollectionWrapper
      data-testid="homepage-experiences-summer-collection-captain-box"
      className={`ExperiencesSummerCollectionModule ${orientation === 'right' ? 'captain' : ''}`}
      ref={domRef}
    >
      <div className="cap">
        <FadeInSection isSlow isSmallerMargin isVisibleProp={isVisible} isRight>
          <div className="desktopWrap">
            <Image height={380} width={975} className="imgDesktop" src={desktopJPG} alt="Own a boat" />
          </div>
          <div className={orientation === 'right' ? '' : 'mobilePicture'}>
            <div className="imgMobile">
              <Image loading="lazy" width={285} height={380} src={mobileJPG} alt="Own a boat" />
            </div>
          </div>
        </FadeInSection>

        <FadeInSection isSlow isSmallerMargin isVisibleProp={isVisible}>
          <div className="box">{children}</div>
        </FadeInSection>
      </div>
    </ExperiencesSummerCollectionWrapper>
  );
};

const ExperiencesSummerCollectionWrapper = styled.div`
  margin: 200px auto 0 auto;

  .cap {
    // overflow: hidden;
  }

  &.captain {
    @media ${mediaMdMax} {
      .imgDesktop {
        margin-left: 0;
        left: unset !important;
        float: left !important;
        transform-origin: top left !important;
      }
    }

    .imgMobile {
      @media ${mediaMdMin} {
        display: none;
      }
      margin-right: auto;
      padding-left: 0px;
      margin-left: unset;
    }

    .box {
      @media ${mediaSmMax} {
        transform: unset !important;
        bottom: -135px;
        min-width: 286px;
        max-width: 310px;
        max-height: 300px;
        width: calc(100% - 25px);
      }
    }
  }

  &.captain {
    h2 {
      color: #083170 !important;
    }

    img {
      padding-left: 0 !important;
    }

    .desktopWrap {
      right: 0;
      left: 0 !important;
      height: 380px;

      @media ${mediaSmMax} {
        display: none;
      }

      .imgDesktop {
        float: right;
        position: relative;
        @media ${mediaMdMax} {
          left: 30%;
        }
        @media ${mediaSmMax} {
          display: none;
          left: unset;
        }
      }
      .imgMobile {
        @media ${mediaMdMax} {
          display: none;
        }
        margin-left: auto;
        padding-left: 0px;
      }

      img {
        border-radius: 16px;
      }
    }

    .box {
      background: #f4c064 !important;
      right: 0;

      //transform: translate3d(0, -115%, 0) !important;
      margin-left: auto;
    }

    a {
      color: #154353 !important;
      text-decoration: underline #083170 !important;
    }
  }

  .desktopWrap {
    @media ${mediaSmMax} {
      display: none;
    }

    position: relative;
    overflow: hidden;
    left: 90px;
    border-radius: 16px;
    margin-right: 90px;
    height: 380px;

    img {
      border-radius: 16px;
    }
  }

  @media ${mediaSmMax} {
    height: 481px;
  }

  @media ${mediaSmMax} {
    margin: 105px auto 56px auto;

    .cap {
      overflow: unset;
      display: flex;
    }
  }

  .imgDesktop {
    float: right;
    position: relative;
    @media ${mediaMdMax} {
      left: 30%;
    }
    @media ${mediaSmMax} {
      display: none;
      left: unset;
    }
  }

  .mobilePicture {
    margin-left: auto;
  }

  .imgMobile {
    @media ${mediaMdMin} {
      display: none !important;
    }

    img {
      border-radius: 16px;
    }

    margin-left: 35px;
  }
  img {
    margin-left: 90px;
  }

  .box {
    background-color: #f4c064;
    position: absolute;
    z-index: 2;
    height: auto;
    padding: 45px;
    max-width: 320px;
    width: fit-content;

    transform: translateY(-355px);

    @media ${mediaSmMax} {
      bottom: -75px;
      transform: unset;
      min-width: 286px;
      max-width: 230px;
      max-height: 240px;
      padding: 35px;
      width: calc(100% - 25px);
    }

    h2 {
      color: #154353 !important;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;

      @media ${mediaSmMax} {
        font-size: 24px;
        line-height: 29px;
      }
    }
    p {
      color: #154353;
    }

    a {
      color: #154353;
      text-decoration: underline #083170;
      font-weight: bold;
    }
  }
`;
