import styled from 'styled-components';
import { mediaLgMax, mediaMdMax, mediaMSmMax, mediaSmMax } from 'helpers/breakpoints';
import Image from 'next/image';
import { reviewsData } from 'components/homepage/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import useBreakpoints, { SMALLSCREEN_QUERY } from 'hooks/useBreakpoints';
import { Navigation, Pagination } from 'swiper';

export const Review = () => {
  const [smallScreen] = useBreakpoints(SMALLSCREEN_QUERY);

  return (
    <ReviewWrapper data-testid="homepage-review-wrapper" className="review">
      <div className="cap">
        <h2>See what sea lovers are saying</h2>

        <div className="container">
          <Swiper
            modules={[Pagination, Navigation]}
            draggable
            centeredSlides
            spaceBetween={smallScreen ? 20 : 50}
            navigation={{
              prevEl: '.prevArrowReview',
              nextEl: '.nextArrowReview',
            }}
            className="carousel"
            slidesPerView={smallScreen ? 1.2 : 1}
          >
            {reviewsData.map((review, index) => (
              <SwiperSlide data-swiper-slide-index={index} key={review.name}>
                <div className="carouselItem">
                  <div className="text">
                    <div className="boatImage">
                      <Image
                        data-testid="homepage-review-boat-image"
                        className=""
                        src={review.boat}
                        alt=""
                        fill
                        sizes="100vw"
                      />
                    </div>

                    <blockquote data-testid="homepage-review-text">“{review.review}”</blockquote>
                  </div>
                  <div className="author">
                    <img
                      data-testid="homepage-review-author-image"
                      className="authorImage"
                      loading="lazy"
                      src={review.profile}
                      alt=""
                    />
                    <div className="data">
                      <div data-testid="homepage-review-author-name" className="name">
                        {review.name}
                      </div>
                      <div className="stars">
                        <img loading="lazy" src="/images/homepage/star.svg" alt="" />
                        <img loading="lazy" src="/images/homepage/star.svg" alt="" />
                        <img loading="lazy" src="/images/homepage/star.svg" alt="" />
                        <img loading="lazy" src="/images/homepage/star.svg" alt="" />
                        <img loading="lazy" src="/images/homepage/star.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="nav">
          <img
            src="/images/homepage/carousel-left.svg"
            loading="lazy"
            alt="left"
            className="prevArrowReview"
            data-testid="homepage-review-left-arrow"
          />
          <img
            src="/images/homepage/carousel-left.svg"
            loading="lazy"
            alt="right arrow"
            className="right nextArrowReview"
            data-testid="homepage-review-right-arrow"
          />
        </div>
      </div>
    </ReviewWrapper>
  );
};

const ReviewWrapper = styled.div`
  background: #bc350a;
  margin-top: 125px;
  @media ${mediaMdMax} {
    margin-top: 22px;
  }
  .cap {
    display: flex;
    justify-content: space-between;

    @media ${mediaLgMax} {
      flex-direction: column;
      align-items: center;
    }

    .container {
      transform: translateY(55px);

      max-height: 255px;
      //overflow: hidden;
      width: 100%;
      height: 100%;
      max-width: 670px;
      //padding-bottom: 30px;

      @media ${mediaLgMax} {
        //overflow: unset;?
        max-width: unset;
      }

      @media ${mediaSmMax} {
        max-height: unset;
        padding-bottom: 0px;
      }

      .carousel {
        width: 100%;
        outline: none;
        @media ${mediaSmMax} {
          overflow: visible !important;
        }

        .slick-list {
          overflow: visible;
          margin: 0 -1000px;
          @media ${mediaSmMax} {
            margin-left: -20px;
            margin-right: 0;
          }
        }

        .slick-slide > div {
          margin: 0 1000px;
          @media ${mediaSmMax} {
            margin-left: 20px;
            margin-right: 0;
          }
        }
      }

      .carouselItem {
        box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 22px;
        margin: 2px 32px 22px 32px;
        ${mediaMSmMax} {
          margin: 0;
        }

        padding: 28px;
        height: 205px;
        max-width: 600px;
        background-color: white;
        border-radius: 10px;

        @media ${mediaLgMax} {
          border-radius: unset;
        }

        @media ${mediaSmMax} {
          height: 450px;
          border-radius: 10px;
          max-width: 310px;
        }

        .boatImage {
          min-width: 230px;
          width: 230px;
          height: 150px;
          max-height: 150px;

          position: relative;

          img {
            border-radius: 10px;
          }

          @media ${mediaSmMax} {
            width: 100%;
            height: 190px;
            max-height: unset;
          }
        }
      }

      .author {
        position: absolute;
        transform: translate3d(100%, -50%, 0);
        //left: -200px;
        display: flex;

        @media ${mediaSmMax} {
          position: relative;
          transform: none;
        }

        .authorImage {
          border-radius: 10px;
          border: 2px solid #ffffff;
          width: 74px;
          height: 74px;
          border-radius: 100%;

          @media ${mediaMdMax} {
            border-radius: 100%;
          }

          @media ${mediaSmMax} {
            border-radius: 100%;
            border: none;
            width: 74px;
            height: 74px;
          }
        }

        .data {
          margin: auto 0 auto 10px;

          .name {
            font-size: 18px;
            font-weight: 600;
          }

          .stars {
            display: flex;
            img {
              padding-right: 5px;
            }
          }
        }
      }

      .text {
        display: flex;

        @media ${mediaSmMax} {
          flex-direction: column;
          align-items: center;
        }

        blockquote {
          max-width: 50%;
          padding-left: 25px;

          @media ${mediaSmMax} {
            max-width: unset;
            padding-left: 0px;
            min-height: 90px;
            margin-top: 20px;
            text-align: left;
            margin-bottom: 24px;
          }
        }
      }
    }

    .nav {
      padding-top: 55px;
      z-index: 200;

      @media ${mediaLgMax} {
        display: none;
      }

      img {
        cursor: pointer;
        background: #f5f7f9;
        padding: 16px 19px;
        border-radius: 50%;

        &.right {
          margin-left: 20px;
          transform: rotateZ(180deg);
        }
      }
    }

    h2 {
      padding-top: 55px;
      color: #f5ece4;
      width: 200px;
      font-weight: bold;

      @media ${mediaLgMax} {
        width: 100%;
        font-size: 32px;
      }

      @media ${mediaSmMax} {
        font-size: 24px;
      }
    }
  }
`;
