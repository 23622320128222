import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { mediaLgMax, mediaSmMax } from 'helpers/breakpoints';
import Image from 'next/image';
import { experiencesData } from 'components/homepage/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useBreakpoint } from '../BreakpointProvider';

const MobileComponent = ({ data }) => (
  <Swiper slidesPerView={1.4} className="carouselMobile" loop>
    {data.flat().map((item, i) => (
      <SwiperSlide key={`${item.link}-${i}`}>
        <a href={item.link}>
          <div className="carouselItem">
            <Image
              src={item.imageJPG}
              quality={50}
              alt={item.title}
              fill
              // sizes="100vw"
              sizes="(max-width: 768px) 70vw, 50vw"
              style={{
                objectPosition: item.mobileObjectPosition,
                objectFit: 'cover',
              }}
            />
            <h3>{item.title}</h3>
            <div className="gradient" />
          </div>
        </a>
      </SwiperSlide>
    ))}
  </Swiper>
);

const DesktopComponent = ({ data }) => {
  const slide1 = data.flat().filter((item, index) => index % 2 === 0);
  const slide2 = data.flat().filter((item, index) => index % 2 === 1);

  return (
    <>
      <div>
        <Swiper
          loop
          draggable={false}
          slidesPerView={1}
          className="carouselFlickity"
          modules={[Pagination, Navigation]}
          navigation={{
            prevEl: '.prevArrow',
            nextEl: '.nextArrow',
          }}
        >
          <SwiperSlide>
            <div className="grid">
              {slide1.map((item, i) => (
                <a
                  href={item.link}
                  key={item.title}
                  className={[i < 2 ? 'carouselTop' : 'carouselBottom', 'place'].join(' ')}
                  style={{ gridArea: item.grid }}
                  data-testid="homepage-experiences-slide1-experience-box"
                >
                  <Image
                    src={item.imageJPG}
                    quality={60}
                    alt={item.title}
                    fill
                    sizes="100vw"
                    style={{
                      objectPosition: item.objectPosition,
                    }}
                  />

                  <h3>{item.title}</h3>
                  <div className="gradient" />
                </a>
              ))}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid">
              {slide2.map((item, i) => (
                <a
                  href={item.link}
                  key={item.title}
                  className={[i < 2 ? 'carouselTop' : 'carouselBottom', 'place'].join(' ')}
                  style={{ gridArea: item.grid }}
                  data-testid="homepage-experiences-slide2-experience-box"
                >
                  <Image
                    src={item.imageJPG}
                    quality={50}
                    alt={item.title}
                    fill
                    sizes="100vw"
                    style={{
                      objectPosition: item.objectPosition,
                    }}
                  />
                  <h3>{item.title}</h3>
                  <div className="gradient" />
                </a>
              ))}
              <div className="nav" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="nav">
        <img
          src="/images/homepage/carousel-left.svg"
          loading="lazy"
          className="prevArrow"
          alt="left arrow"
          data-testid="homepage-experiences-left-arrow"
        />
        <img
          src="/images/homepage/carousel-left.svg"
          loading="lazy"
          alt="right arrow"
          className="right nextArrow"
          data-testid="homepage-experiences-right-arrow"
        />
      </div>
    </>
  );
};

const Experiences = ({ reducedTest }) => {
  const data = experiencesData;

  const [ref] = useState(null);

  useEffect(() => {
    if (ref) {
      ref.once('ready', () => {
        setTimeout(() => {
          ref.select(0);
          ref.resize();
        }, 300);
      });
    }
  }, [ref]);

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.lg;

  return (
    <FindNextWrapper
      data-testid="homepage-experiences-wrapper"
      className={`${reducedTest ? 'reducedContentPadding' : ''}`}
    >
      {/* <img src={'/images/homepage/circles.svg'} className="circles" alt="" /> */}
      <img className="circles" src="/images/homepage/circles.svg" alt="Circle decoration" loading="lazy" />
      <div className="cap container">
        <h2>Discover your next on-the-water adventure</h2>
        {smallScreen ? <MobileComponent data={data} /> : <DesktopComponent data={data} />}
      </div>
    </FindNextWrapper>
  );
};

export default Experiences;

const FindNextWrapper = styled.div`
  background-color: #f5ece4;
  margin-top: 128px;
  position: relative;

  &.reducedContentPadding {
    margin-top: 255px;
  }

  .circles {
    max-height: 500px;
    position: absolute;
    height: 100%;
    @media ${mediaLgMax} {
      transform: translateX(-50%);
    }
  }

  .grid {
    display: grid !important;
    z-index: 32;
    margin-right: 1000px;
    grid-template-columns: 20px 233px 221px 166px 166px 156px;
    grid-template-rows: 280px 200px;
    gap: 20px;
    grid-template-areas:
      'heading heading sailing pontoon pontoon pontoon'
      '. bachelor bachelorrete speed speed nav';
  }

  .container {
    transform: translateY(50px);

    @media ${mediaLgMax} {
      display: flex;
      flex-wrap: wrap;
      margin-right: 0 !important;
    }

    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      //background: linear-gradient(
      //  0deg,
      //  rgba(23, 35, 60, 0.69) 28.32%,
      //  rgba(23, 35, 60, 0) 65.09%
      //);
      opacity: 0;
      transition: all 0.3s ease;
      @media ${mediaSmMax} {
        opacity: 1;
        background: linear-gradient(0deg, rgba(23, 35, 60, 0.69) 25.32%, rgba(23, 35, 60, 0) 35.09%);
      }
    }

    .gradient:hover {
      opacity: 1;
    }

    .carouselFlickity {
      .slick-list {
        overflow: initial;
        margin: 0 -800px;
      }

      .slick-slide > div {
        margin: 0 800px;
      }

      .place {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
      }

      .carouselTop {
        height: 286px;

        img {
          height: 286px;
        }
      }

      .carouselBottom {
        height: 200px;

        img {
          height: 200px;
        }
      }

      .carouselTop,
      .carouselBottom {
        .carouselItem {
          width: 100%;
          height: fit-content;
          margin-right: 250px;

          @media ${mediaSmMax} {
            padding-right: 10px;
          }
        }

        img {
          border-radius: 10px;
          position: relative;
          object-fit: cover;

          transition: all 500ms ease-in-out;

          &.long {
            width: 100%;
          }
        }

        &:hover {
          h3 {
            opacity: 1;
            transition: opacity 500ms ease-in-out;
          }
          img {
            transform: scale(1.1);
          }

          .gradient {
            opacity: 1;
          }
        }
      }

      h3 {
        font-weight: bold;
        transition: opacity 500ms ease-in-out;
        color: white;
        opacity: 0;
        z-index: 2;
        position: absolute;
        left: 20px;
        bottom: 10px;
      }
    }

    .carouselMobile {
      outline: none;

      .slick-list {
        overflow: initial;
      }

      width: 100%;
      //overflow: auto !important;

      border-radius: 10px;
      .carouselItem {
        height: 321px;
        position: relative;
        width: 250px;
        margin-right: 25px;
        border-radius: 10px;
        overflow: hidden;

        &.long {
          width: 450px;
        }

        img {
          height: 321px;
          position: relative;
          border-radius: 10px;
          //width: 90%;
        }

        h3 {
          font-weight: bold;
          transition: opacity 500ms ease-in-out;
          color: white;
          z-index: 2;
          position: absolute;
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
          left: 20px;
          bottom: 0;
        }
      }
    }
    h2 {
      //margin: auto;
      top: 70px;
      max-width: 290px;
      position: absolute;
      color: #bc350a;
      grid-area: heading;

      font-weight: bold;
      font-size: 32px;
      line-height: 38px;

      @media ${mediaSmMax} {
        font-size: 24px;
        line-height: 29px;
      }

      @media ${mediaSmMax} {
        margin: unset;
        //width: 250px;
        padding-left: 0 !important;
      }
      @media ${mediaLgMax} {
        margin: unset;
        position: unset;
        max-width: unset;

        padding-bottom: 20px;
        top: unset;
        padding-left: 40px;
        //width: 400px;
      }
    }

    .nav {
      grid-area: nav;
      margin-left: auto;
      position: absolute;
      right: 10px;
      bottom: 144px;
      z-index: 12;

      img {
        cursor: pointer;
        background: white;
        padding: 16px 19px;
        border-radius: 50%;

        &.right {
          margin-left: 20px;
          transform: rotateZ(180deg);
        }
      }
    }
  }
`;
