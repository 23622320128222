import styled from 'styled-components';
import { getMaxQueryWidth, mediaMLgMax, mediaMMdMax, mediaMSmMax } from 'helpers/breakpoints';
import Image from 'next/image';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useEffect, useState } from 'react';

const CopyTitle = () => <h2>Let&apos;s go boating</h2>;

const SubCopy = () => <h3>Find boat rentals in hundreds of locations worldwide. Licensed Captains available.</h3>;

const Hero = () => {
  const isMobileScreenQuery = useMediaQuery(getMaxQueryWidth(640));
  const renderMobile = isMobileScreenQuery;
  const [heroBackImage, setHeroBackImage] = useState('new-hero-full-102224.png');

  const heroPath = '/images/homepage/hero/';
  const heroImage = `${heroPath}${heroBackImage}`;

  useEffect(() => {
    if (renderMobile) {
      setHeroBackImage('new-hero-mobile-102224.png');
    } else {
      setHeroBackImage('new-hero-full-102224.png');
    }
  }, [renderMobile]);

  return (
    <HeroWrapper data-testid="homepage-hero-wrapper">
      <BgImageWrapper>
        <Image
          priority
          src={heroImage}
          alt=""
          data-testid="homepage-hero-image"
          fill
          sizes="(min-width: 640px) 1140px, 100vw"
          style={{
            objectFit: 'cover',
          }}
        />
      </BgImageWrapper>
      <div className="text" data-testid="homepage-hero-text">
        <CopyTitle />
        <SubCopy />
      </div>
    </HeroWrapper>
  );
};

export default Hero;

const HeroWrapper = styled.div`
  display: flex;
  min-height: 575px;
  position: relative;
  margin-bottom: -160px;
  justify-content: center;

  ${mediaMSmMax} {
    min-height: 428px;
    margin-bottom: -80px;
  }

  .cap-hero {
    max-width: 1076px;
    width: 100%;
    margin: 140px auto auto auto;
    position: relative;

    ${mediaMLgMax} {
      width: 100%;
      display: flex;
      margin: 140px 40px auto;
    }

    ${mediaMSmMax} {
      display: block;
      margin: 0 22px;
      width: unset;
    }
  }

  h3 {
    color: #17233c;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 22px;
    line-height: 28.6px;
    font-weight: 500;
    max-width: 456px;

    span {
      color: #f4c064;
    }

    ${mediaMSmMax} {
      font-size: 18px;
      line-height: 24.3px;
      max-width: 300px;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 10px;
    color: #17233c;
    z-index: 1;

    ${mediaMSmMax} {
      font-size: 25px;
      line-height: 37.8px;
      max-width: 240px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 39px;
    line-height: 55px;
    margin-bottom: 10px;
    color: #17233c;
    z-index: 1;

    ${mediaMSmMax} {
      font-size: 28px;
      line-height: 35px;
      max-width: 258px;
    }
  }

  .text {
    color: #17233c;
    z-index: 1;
    width: 100%;
    margin-top: 69px;
    max-width: 1076px;
    margin-left: 40px;
    margin-right: 40px;

    ${mediaMMdMax} {
      max-width: unset;
      padding: 0 20px;
      margin-top: 29px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
`;

const BgImageWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  position: absolute;
  height: 575px;

  ${mediaMSmMax} {
    height: 428px;
  }
`;
